import { Grid, Card, Typography, Badge, Box, } from "@mui/material";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { RootState } from "../../redux/store";
import { addToCart, removeByOneFromCart } from "../../redux/reducers/cartReducer";
import { ServiceItem } from "orderme-api-integration-client";
import { AddButton, RemoveButton } from "./productCardStyles"
import { roundDoubleNumbers } from "../../helpers/currency";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getServicePrice } from "../../helpers/costCounter";
import InfoIcon from '@mui/icons-material/Info';
import { ProductPrices } from "./productPrices";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import FriendsBadge from "./friendsBadge";

interface ProductCardProps {
  item: ServiceItem;
  backgroundColor?: string;
  fontSize: number;
  fontColor: string;
  buttonSize: number;
}

export function ProductCard(props: ProductCardProps) {
  const dispatch = useAppDispatch();
  const { item, backgroundColor, fontSize, fontColor, buttonSize } = props;
  const servicesInBasket: ServiceItem[] = useAppSelector((state: RootState) => state.cartState.servicesInBasket);
  const serviceCount: number = servicesInBasket.filter(service => service.id === item.id).length;
  const servicePrice: number = getServicePrice(item, serviceCount);
  const [isPricesVisible, setIsPricesVisible] = useState(false);
  const { t } = useTranslation('services');
  const isLoggedIn = useAppSelector((state: RootState) => state.authState.isLoggedIn);
  const hasAmountPrice = item.prices?.length > 0;
  const isPriceForLoggedInUser = !hasAmountPrice && item.originalPrice > servicePrice;
  const showFriendsPrice = !isLoggedIn && isPriceForLoggedInUser;

  const addToBasket = () => {
    dispatch(addToCart(item));
  }

  const removeFromBasket = () => {
    dispatch(removeByOneFromCart(item));
  }

  const togglePricesVisible = () => {
    setIsPricesVisible(!isPricesVisible);
  };

  return (
    <Card sx={{ borderRadius: "10px", mt: "10px", height: "auto", width: "100%", backgroundColor: backgroundColor }}>
      <Grid container
        direction="row"
        sx={{ margin: "15px", pl: "5px", pr: "25px" }}
        justifyContent="space-between"
        alignItems="center">
        {showFriendsPrice &&
          <Box sx={{
            position: "absolute", right: "15px", top: "10px"
          }}>
            <FriendsBadge />
          </Box>
        }
        <Grid item xs container alignItems="center" sx={{ color: fontColor, fontSize: fontSize }}>
          {item.name}
        </Grid>
        <Grid item xs="auto"
          sx={{
            height: "auto",
            color: fontColor,
            fontSize: fontSize,
            mx: "5px"
          }}
          textAlign="right">
          <Grid container direction="column">
            {
              <Grid container direction="row" justifyContent="flex-end">
                <Grid sx={{
                  opacity: !showFriendsPrice ? 0.7 : null,
                  color: fontColor,
                  fontSize: fontSize,
                  textDecoration: !showFriendsPrice ? "line-through" : null,
                  pr: "15px",
                  display: item.originalPrice - servicePrice === 0 ? "none" : "inline-block"
                }}>
                  {roundDoubleNumbers(item.originalPrice)} €
                </Grid>
                <Grid sx={{ fontWeight: showFriendsPrice ? "bold" : null }}>
                  {roundDoubleNumbers(servicePrice)} €
                </Grid>
              </Grid>
            }
          </Grid>
        </Grid>
      </Grid >
      {
        hasAmountPrice &&
        <Grid container direction="row" alignItems="center" justifyContent="flex-end"
          sx={{
            backgroundColor: "white",
            pr: "25px",
            pt: "5px",
          }} >
          <Typography alignItems="right" sx={{ fontSize: "14px" }}>{t("bulkDiscount")}</Typography>
          <InfoIcon fontSize="small" sx={{ color: "paulini.blue" }} onClick={togglePricesVisible} />
        </Grid>
      }
      <Grid container direction="row" justifyContent="space-around" sx={{ backgroundColor: "white" }}>
        <RemoveButton onClick={() => removeFromBasket()}>
          <RemoveCircleOutlineIcon sx={{ height: buttonSize, width: buttonSize, color: "orange" }} />
        </RemoveButton>
        <Typography sx={{ marginTop: "15px", fontWeight: "bold", fontSize: "25px" }}>
          {serviceCount}
        </Typography>
        <AddButton onClick={() => addToBasket()}>
          <AddCircleOutlineIcon sx={{ height: buttonSize, width: buttonSize, color: "orange" }} />
        </AddButton>
      </Grid>
      {
        hasAmountPrice &&
        <ProductPrices serviceName={item.name} prices={item.prices} open={isPricesVisible} onClose={togglePricesVisible} />
      }
    </Card >
  );
}