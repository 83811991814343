import { SvgIcon, SvgIconProps } from '@mui/material';

function DetergentsIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <svg height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg">
                <g fill="currentColor">
                    <circle cx="61" cy="376" r="45" />
                    <circle cx="106" cy="241" r="60" />
                    <path d="m256 30h105.601c4.882 0 9.619.817 14.399 1.392v-31.392h-120z" />
                    <path d="m376 466c0-24.814-20.186-45-45-45-7.432 0-14.343 1.983-20.526 5.186-9.47-20.702-30.256-35.186-54.474-35.186-2.708 0-5.294.45-7.908.8-10.291-18.281-29.654-30.8-52.092-30.8-33.091 0-60 26.909-60 60 0 .917.231 1.771.271 2.679-17.56 6.138-30.271 22.692-30.271 42.321 0 24.814 20.186 46 45 46h180c24.814 0 45-21.186 45-46z" />
                    <path d="m361.601 60h-105.601v15c0 24.8-13.374 46.19-27.539 67.768-15.952 24.316-32.461 49.453-32.461 83.232v105c25.767 0 50.068 11.265 66.958 30.278 23.364 1.831 44.956 12.847 60.278 30.132 2.578-.278 5.156-.41 7.764-.41 41.353 0 75 33.647 75 75 0 16.948-5.865 33.426-15.383 46h105.383v-265.053c0-23.525-3.995-49.688-13.458-75.63-25.137-69.082-70.976-111.317-120.941-111.317zm52.06 231.801c-32.225 11.849-61.243-36.727-73.066-69.17-14.824-40.737-17.549-89.399 11.514-99.976 28.945-10.62 58.213 28.462 73.052 69.185 14.823 40.737 17.548 89.385-11.5 99.961z" />
                    <path d="m361.557 151c-2.021 2.871-5.522 26.338 7.222 61.362 12.759 35.01 30.513 50.742 35.435 51.094 2.021-2.871 5.508-26.338-7.236-61.362s-30.499-50.742-35.421-51.094z" />
                </g>
            </svg>
        </SvgIcon>
    );
}

export default DetergentsIcon;