import { useEffect, useRef, useState } from 'react';
import Carousel from 'react-material-ui-carousel';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Grid } from '@mui/material';
import { ServiceClient } from '../../helpers/client';
import { CustomerInfo, ServiceGroup } from 'orderme-api-integration-client';
import { ServiceLogoItem } from '../serviceLogoItem/serviceLogoItem';
import { useNavigate } from 'react-router';
import { useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';

export function ServiceCarousel() {
    const [categoryId, setCategoryId] = useState<string>();
    const navigate = useNavigate();
    const [categoryGroups, setCategoryGroups] = useState<ServiceGroup[]>();
    const servicesClient: ServiceClient = new ServiceClient();
    const elementRef = useRef<HTMLDivElement>(null)
    const [numberOfItems, setNumberOfItems] = useState<number>(0);
    const [categoryGroupsItems, setCategoryGroupsItems] = useState<ServiceGroup[][]>();
    const customerInfo: CustomerInfo = useAppSelector((state: RootState) => state.customerState.customerInfo);

    useEffect(() => {
        servicesClient.getCategories()
            .then((response) => {
                setCategoryId(response[0].id);
            })
            .catch((err) => {
                setCategoryGroups(null);
                console.log(err);
            });
    }, [])

    useEffect(() => {
        if (!categoryId) {
            setCategoryGroups(null);
            return;
        }

        servicesClient.getCategoryGroups(categoryId, customerInfo.id)
            .then((response) => {
                setCategoryGroups(response);
            })
            .catch((err) => {
                setCategoryGroups(null);
                console.log(err);
            });
    }, [categoryId, customerInfo])

    useEffect(() => {
        const element = elementRef?.current;
        if (!element)
            return;

        const observer = new ResizeObserver(() => {
            if (elementRef.current.offsetWidth > 1000)
                setNumberOfItems(3);
            else if (elementRef.current.offsetWidth > 600)
                setNumberOfItems(2);
            else
                setNumberOfItems(1);
        });

        observer.observe(element);
        return () => {
            observer.disconnect();
        };
    }, [])

    useEffect(() => {
        if (!categoryGroups || numberOfItems === 0)
            return;

        // Split categories into groups which contains numberOfItems in total
        const chunks = [];
        for (let i = 0; i < categoryGroups.length; i += numberOfItems) {
            const chunk = categoryGroups.slice(i, i + numberOfItems);
            chunks.push(chunk);
        }

        // Last item can have less items than required.
        // So add items from the beginning to fill a list
        var lastItem = chunks[chunks.length - 1];
        if (lastItem.length < numberOfItems) {
            chunks[chunks.length - 1] = lastItem.concat(...categoryGroups.slice(0, numberOfItems - lastItem.length));
        }

        setCategoryGroupsItems(chunks);
    }, [numberOfItems, categoryGroups])

    const showCategoryGroup = (serviceGroup: ServiceGroup) => {
        navigate('/category/' + categoryId + "?group=" + serviceGroup.id);
    }

    return (
        <Grid ref={elementRef} container direction="column" alignItems="center">
            {categoryGroupsItems && numberOfItems > 0 &&
                (<Carousel navButtonsAlwaysVisible sx={{ maxWidth: "1500px", width: "100%" }}
                    navButtonsProps={{ style: { background: 'none', borderRadius: 0 } }}
                    navButtonsWrapperProps={{ style: { marginTop: "-35px" } }}
                    indicatorContainerProps={{ style: { marginBottom: "20px" } }}
                    NextIcon={<ArrowForwardIosIcon sx={{ width: "40px", height: "40px", mr: "-15px" }} />}
                    PrevIcon={<ArrowBackIosIcon sx={{ width: "40px", height: "40px", ml: "-5px" }} />}
                >
                    {
                        categoryGroupsItems.map((services, id) => (
                            <Grid key={id} container direction="row">
                                {
                                    services.map((service, _) => (
                                        <Grid key={service.id} item xs sx={{
                                            p: "5px",
                                            height: "350px"
                                        }}>
                                            <ServiceLogoItem
                                                key={service.id}
                                                service={service}
                                                isButton={true}
                                                imageWidth={800}
                                                open={() => { showCategoryGroup(service); }} />
                                        </Grid>))
                                }
                            </Grid>
                        ))
                    }
                </Carousel>)
            }
        </Grid>
    );
}