import { useState, useEffect } from 'react';
import { AppBar, Tabs, Grid, Typography, IconButton, iconButtonClasses, styled, Tab } from '@mui/material';
import { ServiceCategory } from 'orderme-api-integration-client';
import { ServiceClient } from "../../helpers/client";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { apiKey, imagesUri } from '../../helpers/clientConfigs';
import { Link, matchPath, useLocation, } from 'react-router-dom';

const imageSize = 60;

const ScrollIconButton = styled(IconButton)({
    width: "40px",
    color: "rgba(255, 255, 255, 1)",
    [`&.${iconButtonClasses.disabled}`]: { color: "rgba(255, 255, 255, 0.3)" }
});

export function CategoriesAppBar() {
    const location = useLocation();
    const servicesClient: ServiceClient = new ServiceClient();
    const [serviceCategories, setServiceCategories] = useState<ServiceCategory[]>();

    useEffect(() => {
        serviceCategoriesFetcher();
    }, [])

    const serviceCategoriesFetcher = () => {
        servicesClient.getCategories().then((response) => {
            setServiceCategories(response);
        }).catch((err) => console.log(err));
    }

    const toggleScrollButtons = (props: any) => {
        if (props.direction === "left") {
            return (
                <ScrollIconButton disableRipple {...props} sx={{ marginRight: "4px", }} >
                    <ArrowBackIosIcon sx={{ width: "40px", height: "40px" }} />
                </ScrollIconButton>
            );
        } else if (props.direction === "right") {
            return (
                <ScrollIconButton disableRipple {...props} sx={{ marginLeft: "2px", }}>
                    <ArrowForwardIosIcon sx={{ width: "40px", height: "40px" }} />
                </ScrollIconButton>
            );
        } else {
            return null;
        }
    }

    const getWebsiteCategoryGrid = (category: ServiceCategory) => {
        return <Grid
            container direction="column" justifyContent="center" alignItems="center">
            <img width={imageSize} height={imageSize} src={`${imagesUri}/${category.logoId}?orderme-api-key=${apiKey}&width=${imageSize * 2}`} alt={category.name} />
            <Typography
                display={{ xs: "none", md: 'block' }}
                sx={{
                    color: "white",
                    pt: "5px"
                }}>{category.name}</Typography>
        </Grid>
    }

    function getCurrentTab() {
        if (!serviceCategories)
            return false;

        const pathname = location.pathname;
        for (let i = 0; i < serviceCategories.length; i += 1) {
            const pattern = "category/" + serviceCategories[i].id;
            const possibleMatch = matchPath(pattern, pathname);
            if (possibleMatch !== null) {
                return serviceCategories[i].id;
            }
        }

        return false;
    }

    const currentTab = getCurrentTab();

    return (
        <Grid sx={{
            width: '100%',
            backgroundColor: 'background.paper',
            mb: "20px",
        }}>
            <AppBar sx={{ backgroundColor: "paulini.blue" }}
                elevation={0} position="static" color="default">
                <Tabs sx={{ overflow: "auto", width: "100%" }}
                    allowScrollButtonsMobile
                    value={currentTab}
                    variant="scrollable"
                    TabIndicatorProps={{ sx: { backgroundColor: "paulini.orange", height: "5px" } }}
                    ScrollButtonComponent={(props) => toggleScrollButtons(props)}>
                    {(serviceCategories)?.map((category, _) => {
                        return currentTab === category.id ? (
                            <Tab sx={{ padding: "6px 12px", minWidth: "fit-content", flex: 1 }}
                                value={category.id}
                                key={category.id}
                                label={getWebsiteCategoryGrid(category)} />)
                            :
                            (<Tab sx={{ padding: "6px 12px", minWidth: "fit-content", flex: 1 }}
                                value={category.id}
                                key={category.id}
                                to={"/category/" + category.id}
                                component={Link}
                                label={getWebsiteCategoryGrid(category)} />);
                    })}
                </Tabs>
            </AppBar>
        </Grid >
    );
}