import { AppStyle } from "./appStyle";
import { env } from "../env";

export const basePath: string = env.REACT_APP_API_URL;
export const basePathWithApi: string = basePath + "/api/v1.8";
export const filesUri: string = basePathWithApi + "/Files";
export const imagesUri: string = filesUri + "/images";
export const refreshTokenUri: string = basePathWithApi + "/Customers/refreshToken";

export const apiKey: string = env.REACT_APP_API_KEY;

export const appStyleMode: AppStyle =
    env.REACT_APP_TYPE === "mobile" ? AppStyle.Mobile : AppStyle.Paulini;