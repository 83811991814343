import { useEffect, useState } from "react";
import { Typography, Grid, Card, CardHeader, CardContent, Toolbar, Box } from "@mui/material";
import { Order, OrderStatus, SalesPoint, CustomerInfo, OrderItem } from "orderme-api-integration-client";
import { NavBar } from "../appBars/navBar/navBar"
import { useNavigate } from "react-router-dom";
import { BottomCartBar } from "../appBars/cartBar/bottomCartBar";
import ProgressIcon from "../../logo/progress.png"
import TagIcon from "../../logo/triangle.png"
import { getProgress, getStatusTranslation } from "../../helpers/orderStatus";
import moment from "moment";
import InfoIcon from "../../logo/info_blue.png"
import { OrderStatusLegend } from "./orderStatusLegend";
import { useTranslation } from 'react-i18next';
import { Label } from "../../controls";
import { OrderClient, SalePointClient } from "../../helpers/client"
import { RootState } from "../../redux/store";
import { useAppSelector } from "../../redux/hooks";
import {
  CancelPaymentButton,
  DetailDatesBox,
  DiscountTextTypography,
  HeaderText,
  InfoIconStyled,
  InfoItemName,
  InfoItemValue,
  OrderDetailsGrid,
  OrderStatusAreaGrid,
  ProceedPaymentButton,
  ProgressIconStyled,
  Separator,
  TagIconStyled,
  UnpaidAreaGrid
} from "./orderDetailsStyles";
import { BreadcrumbsBar } from "../breadcrumbs/breadcrumbsBar";
import { appStyleMode } from "../../helpers/clientConfigs";
import { AppStyle } from "../../helpers/appStyle";
import { PaymentParam } from "../payment/payments";
import useGetParameter from "../../hooks/useGetParameter";
import { RouterParam } from "../../helpers/routerParam";
import { assignCustomerDetails } from "../../helpers/customerHelper";
import { OrderItemsGroup } from "./orderItemsGroup";

interface PhoneProps {
  phone: string;
}
function Phone(props: PhoneProps) {
  return (<a href={`tel:${props.phone}`}>{props.phone}</a>)
}

export function OrderDetails() {
  const navigate = useNavigate();
  const orderId = useGetParameter(RouterParam.Id);
  const orderClient: OrderClient = new OrderClient();
  const customerInfo: CustomerInfo = useAppSelector((state: RootState) => state.customerState.customerInfo);
  const salePointClient: SalePointClient = new SalePointClient();

  const [isBusy, setIsBusy] = useState<boolean>(false);

  const [order, setOrder] = useState<Order>();
  const [orderServices, setOrderServices] = useState<OrderItem[]>();
  const [orderProducts, setOrderProducts] = useState<OrderItem[]>();
  const [destination, setDestination] = useState<SalesPoint>();
  const [isStatusLegendVisible, setIsStatusLegendVisible] = useState(false);
  const { t } = useTranslation('order');
  const [isWaitingForPayment, setIsWaitingForPayment] = useState(false);
  const [isPaid, setIsPaid] = useState(false);
  const userInfo: CustomerInfo = useAppSelector((state: RootState) => state.customerState.customerInfo);

  useEffect(() => {
    if (orderId) {
      orderClient.get2(userInfo.id, orderId).then((response) => {
        setOrder(response);
      }).catch((err) => {
        console.error(err);
        navigate("/home", { replace: true });
      });
    }
    else {
      navigate("/home", { replace: true });
    }
  }, [orderId]);

  useEffect(() => {
    if (!order) {
      setDestination(null);
      setOrderServices([]);
      setOrderProducts([]);
      return;
    }

    setOrderServices(order.items.filter(item => !item.isItem));
    setOrderProducts(order.items.filter(item => item.isItem));

    if (order.destination?.knownId) {
      salePointClient.getById(order.destination.knownId).then((response) => {
        setDestination(response);
      }).catch((err) => {
        console.error(err);
      });
    }

  }, [order]);

  useEffect(() => {
    if (!order) {
      return;
    }

    setIsWaitingForPayment(order.status === OrderStatus.WaitingForPayment);

    setIsPaid(!isWaitingForPayment && order.status !== OrderStatus.Cancelled && order.payment?.paidOn !== undefined);
  }, [order, isWaitingForPayment]);

  const getInfoItem = (name: string, value?: string | JSX.Element) => {
    return (
      value && (
        <Grid sx={{ mt: "5px" }}>
          <InfoItemValue>{value}</InfoItemValue>
          <InfoItemName>{name}</InfoItemName>
        </Grid>));
  }

  const getLocalDate = (date: moment.Moment) => {
    if (!date)
      return "-";

    const localDate = moment.utc(date).local().locale('lt');
    if (localDate.year() === 1)
      return '-';

    return localDate.format('YYYY/MM/DD');
  }

  const toggleOrderStatusLegend = () => {
    setIsStatusLegendVisible(!isStatusLegendVisible);
  };

  const continuePayment = () => {
    navigate(
      '/paymentMethods',
      { state: { orderId: order.id } as PaymentParam });
  };

  const cancelPayment = async () => {
    try {
      setIsBusy(true);
      await orderClient.reject(customerInfo.id, order.id);
      const updatedOrder = await orderClient.get2(customerInfo.id, order.id);
      setOrder(updatedOrder);
      // refresh customer details if order was paid with credits
      if (order.creditsUsed > 0)
        assignCustomerDetails();
    } finally {
      setIsBusy(false);
    }
  };

  return (
    <Grid container direction="column" alignItems="center">
      <NavBar barTitle={t('order', { orderNumber: order?.number })} />
      <BreadcrumbsBar to={"orderHistory/orderDetail"} currentPageName={order?.number} />
      {order && (
        <>
          <OrderDetailsGrid isMobile={appStyleMode === AppStyle.Mobile} className={appStyleMode === AppStyle.Mobile && "order-details"}>
            <Grid sx={{ mx: "15px" }}>
              <TagIconStyled sx={{ left: `${getProgress(order?.status)}%` }} src={TagIcon} />
              <ProgressIconStyled src={ProgressIcon} />

              <OrderStatusAreaGrid container direction="row" justifyContent="space-between">
                <Typography >
                  <InfoIconStyled src={InfoIcon} onClick={toggleOrderStatusLegend}></InfoIconStyled>
                  <Box sx={{ verticalAlign: 'super', ml: "5px" }} component="span">{t('status')}</Box>
                </Typography>
                <Typography sx={{ mt: "3px" }}>{getStatusTranslation(order?.status)}</Typography>
              </OrderStatusAreaGrid>

              <Card sx={{ marginBottom: "20px", margin: "5px" }}>
                <CardHeader sx={{ backgroundColor: "paulini.blue", textAlign: "center" }}
                  title={<HeaderText>{order?.number + " " + t('summary')}</HeaderText>} />
                <CardContent sx={{ paddingTop: "5px" }}>
                  <Grid>
                    {order && !isWaitingForPayment && order.status !== OrderStatus.Cancelled &&
                      (<Grid>
                        {destination && (
                          <Grid>
                            {getInfoItem(t('destinationAddress'), `${destination.name} ${destination.address} ${destination.city}`)}
                            {getInfoItem(t('destinationPhone'), <Phone phone={destination.phone} />)}
                          </Grid>
                        )}
                        {<DetailDatesBox>
                          {getInfoItem(t('orderDate'), getLocalDate(order?.createdOn))}
                          {getInfoItem(t('plannedEndDate'), getLocalDate(order?.returnDate))}
                        </DetailDatesBox>}
                        {order?.destination?.address && getInfoItem(t('deliveryAddress'), order.destination.address.fullAddress)}
                      </Grid>)}


                    {order && order.status === OrderStatus.Cancelled && (
                      <UnpaidAreaGrid>
                        <Label type="error" content={t('cancelled')} />
                      </UnpaidAreaGrid>
                    )}
                    {order && isWaitingForPayment && (
                      <UnpaidAreaGrid>
                        <Label type="error" content={t('notPaid')} />
                        <ProceedPaymentButton isDisabled={isBusy} onClick={continuePayment} content={t('pay')}></ProceedPaymentButton>
                        <CancelPaymentButton isDisabled={isBusy} onClick={cancelPayment} content={t('cancel')}></CancelPaymentButton>
                      </UnpaidAreaGrid>
                    )}
                    {orderServices?.length > 0 && <OrderItemsGroup header={t('orderServices')} items={orderServices} />}
                    {orderProducts?.length > 0 && <OrderItemsGroup header={t('orderProducts')} items={orderProducts} />}
                  </Grid>

                  {order.totalDiscount !== 0 &&
                    <>
                      <Separator />
                      <DiscountTextTypography>{t('discountText', { discount: order.totalDiscount?.toPrice() })}</DiscountTextTypography>
                    </>
                  }

                  <Separator />
                  <Grid sx={{ textAlign: "right", mt: "5px" }}>
                    <Typography sx={{ fontSize: "17px" }}>{t('total', { total: order.totalPrice.toPrice() })}</Typography>
                    {order.creditsUsed > 0 &&
                      <>
                        <Typography sx={{ fontSize: "17px" }}>{t('credits', { credits: order.creditsUsed.toPrice() })}</Typography>
                      </>}

                    <Typography sx={{ fontWeight: "bold" }}>
                      {t('paidTotal', { paid: isPaid ? (order.totalPrice - order.creditsUsed).toPrice() : (0).toPrice() })}
                    </Typography>

                  </Grid>
                </CardContent>
              </Card>
            </Grid>

          </OrderDetailsGrid>
          <OrderStatusLegend open={isStatusLegendVisible} onClose={toggleOrderStatusLegend} />
        </>
      )
      }
      <Toolbar />
      {appStyleMode === AppStyle.Mobile && <BottomCartBar />}
    </Grid>
  );
}
