import { useEffect, useState } from "react";
import { Grid, List, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { BottomCartBar } from "../appBars/cartBar/bottomCartBar";
import { CustomerInfo, ServiceItem } from "orderme-api-integration-client";
import { ServiceClient } from "../../helpers/client"
import { FilteredItem } from "./filteredItem"
import { SearchBar } from "./searchBar"
import { Linear } from "../progressBars/linear"
import latinize from "latinize";
import { NavBar } from "../appBars/navBar/navBar"
import { useTranslation } from "react-i18next";
import { appStyleMode } from "../../helpers/clientConfigs";
import { AppStyle } from "../../helpers/appStyle";
import { StickyCartBar } from "../appBars/cartBar/stickyCartBar";
import { BreadcrumbsBar } from "../breadcrumbs/breadcrumbsBar";
import { useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import DetergentsIcon from "./detergentsIcon";
import LocalLaundryServiceOutlinedIcon from '@mui/icons-material/LocalLaundryServiceOutlined';

enum ServiceType {
    Service,
    PhysicalItem
}

export function AllServices() {
    const [services, setServices] = useState<ServiceItem[]>();
    const [filteredServices, setFilteredServices] = useState<ServiceItem[]>();
    const [search, setSearch] = useState("");
    const servicesClient: ServiceClient = new ServiceClient();
    const customerInfo: CustomerInfo = useAppSelector((state: RootState) => state.customerState.customerInfo);
    const { t } = useTranslation('services');
    const [serviceTypes, setServiceTypes] = useState<ServiceType[]>([ServiceType.Service, ServiceType.PhysicalItem]);

    useEffect(() => {
        if (services) {
            setFilterForServices();
        }
    }, [search, services, serviceTypes]);

    useEffect(() => {
        loadAllServices();
    }, [customerInfo]);

    const loadAllServices = () => {
        servicesClient.getServices(customerInfo.id).then((response) => {
            distinctServices(response)
        }).catch((err) => console.log(err))
    }

    const distinctServices = (services: ServiceItem[]) => {
        let uniqueServices: ServiceItem[] = [];
        services.filter(function (item) {
            var i = uniqueServices.findIndex(x =>
                x.name === item.name && x.price === item.price && x.prices?.length == item.prices?.length);
            if (i <= -1) {
                uniqueServices.push(item);
            }
            return null;
        });

        setServices(uniqueServices);
    }

    const setFilterForServices = () => {
        if (serviceTypes.length === 0) {
            setFilteredServices([]);
            return;
        }

        const term = latinize(search || '').toLowerCase();
        let acceptableServices = services.filter(
            (service) =>
                latinize(service.name).toLowerCase().includes(term)
                && isServiceTypeSelected(service)
        );
        const sortedServices = acceptableServices.sort((a, b) => {
            const isPhysicalItemA = a.isItem;
            const isPhysicalItemB = b.isItem;
            if (isPhysicalItemA && !isPhysicalItemB) {
                return -1;
            } else if (!isPhysicalItemA && isPhysicalItemB) {
                return 1;
            } else {
                return a.name.localeCompare(b.name);
            }
        });

        setFilteredServices(sortedServices);
    }

    const gridStyleWebsite = {
        maxWidth: "1000px",
        minWidth: "300px",
        backgroundColor: "white",
        color: "black",
        borderRadius: "15px"
    } as const;

    const gridStyleMobile = {
        overflow: "visible"
    } as const;

    const handleServiceTypeChange = (_event: React.MouseEvent<HTMLElement>, newServiceTypes: ServiceType[]) => {
        setServiceTypes(newServiceTypes);
    };

    const isServiceTypeSelected = (service: ServiceItem) => {
        if (service.isItem)
            return serviceTypes.includes(ServiceType.PhysicalItem);

        return serviceTypes.includes(ServiceType.Service);
    }

    return (
        <Grid container direction="column" alignItems="center">
            <NavBar barTitle={t('allServices')} />
            {!filteredServices && (<Linear />)}
            <BreadcrumbsBar to={"search"} />
            {filteredServices &&
                (<Grid sx={appStyleMode === AppStyle.Mobile ? gridStyleMobile : gridStyleWebsite} container justifyContent="center" direction="column">
                    <SearchBar setSearch={setSearch} searchValue={search} />
                    <ToggleButtonGroup
                        value={serviceTypes}
                        onChange={handleServiceTypeChange}
                        sx={{ justifyContent: "center" }}
                    >
                        <ToggleButton sx={{ minWidth: "150px" }} value={ServiceType.PhysicalItem}>
                            <DetergentsIcon sx={{
                                color: "paulini.orange",
                                height: "40px",
                                width: "40px",
                                p: "2px",
                                mr: "10px"
                            }} />
                            {t('products')}
                        </ToggleButton>
                        <ToggleButton sx={{ minWidth: "150px" }} value={ServiceType.Service}>
                            <LocalLaundryServiceOutlinedIcon sx={{ height: "50px", width: "50px", color: "paulini.orange" }} />
                            {t('services')}
                        </ToggleButton>
                    </ToggleButtonGroup>
                    <Grid>
                        <List sx={{ overflow: "auto" }}
                            className={appStyleMode === AppStyle.Mobile ? "all-services-list" : null}>
                            {filteredServices.map((service, index) => {
                                return (
                                    <FilteredItem key={index} service={service} />
                                );
                            })}
                        </List>
                    </Grid>
                    {appStyleMode === AppStyle.Mobile ? (<BottomCartBar />) : (<StickyCartBar />)}
                </Grid>)
            }
        </Grid>
    );
}