import { List, ListItem, IconButton } from "@mui/material";
import { CartServicesProps } from "../../interfaces/interfaces"
import { useAppDispatch } from "../../redux/hooks";
import { animated, useTransition } from 'react-spring'
import CancelIcon from '@mui/icons-material/Cancel';
import { removeFromCart } from "../../redux/reducers/cartReducer";
import { CartProductCard } from "../productCard/cartProductCard";

export function CartServicesList(props: CartServicesProps) {
    const dispatch = useAppDispatch();
    const transitions = useTransition(props.services, {
        from: { opacity: 1, transform: 'translateX(0px)' },
        leave: { opacity: 0, transform: 'translateX(-250px)' },
    });

    return (
        <List className={props.className}
            sx={{ width: "100%" }}>
            {transitions((props, service) => (
                <animated.div style={props}>
                    <ListItem sx={{ overflowX: "hidden" }}>
                        <IconButton sx={{ color: "paulini.error", position: "absolute", right: "-10px", top: "-10px" }} onClick={() => dispatch(removeFromCart(service))}>
                            <CancelIcon sx={{ height: "33px", width: "33px" }} />
                        </IconButton>
                        <CartProductCard item={service} />
                    </ListItem>
                </animated.div>
            ))}
        </List>
    );
}