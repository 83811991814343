import { useState } from "react";
import { Typography, List, Grid, Collapse } from "@mui/material";
import 'moment/locale/lt';
import { OrderItemsGroupProps } from "../../interfaces/interfaces";
import { Line, OldPriceTypography, Separator } from "./orderDetailsStyles";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useTranslation } from "react-i18next";

export function OrderItemsGroup(props: OrderItemsGroupProps) {
    const { header, items } = props;
    const [expandedIndex, setExpandedIndex] = useState(null);
    const { t } = useTranslation('order');

    const doesIndexMatch = (index: number) => expandedIndex === index;

    return (
        <>
            <Separator />
            <Typography sx={{ fontSize: "22px" }}> {header}</Typography>
            <List>
                {items.map((item, index) => {
                    return (
                        <Grid key={index}>
                            <Grid sx={{ display: "grid", gridTemplateColumns: "auto auto", }}>
                                <Typography sx={{ fontSize: "17px" }}>{item.name} {item.amount && item.amount !== 1 ? ('x' + item.amount) : ''}</Typography>
                                <Grid sx={{ fontSize: "15px", textAlign: "right", whiteSpace: "nowrap" }}>
                                    {item.discount > 0 && <OldPriceTypography>{item.price.toPrice()}</OldPriceTypography>}
                                    <Typography sx={{ display: "inline-block" }}>{(item.price - item.discount).toPrice()}</Typography>
                                </Grid>
                            </Grid>
                            {!!(item.comments?.length) && (
                                <Grid sx={{ color: "red", cursor: "pointer" }}>
                                    <Grid container direction="row" alignItems="center" onClick={() => setExpandedIndex(doesIndexMatch(index) ? null : index)}>
                                        <KeyboardArrowDownIcon sx={{ transform: `rotate(${doesIndexMatch(index) ? 180 : 0}deg)` }} />
                                        <Typography sx={{ fontSize: "15px" }}>{t('orderItemInfo')}</Typography>
                                    </Grid>
                                    <Collapse in={doesIndexMatch(index)}>
                                        {item.comments?.map((comment, index) => {
                                            return (
                                                <Typography sx={{ fontSize: "15px", paddingLeft: "20px" }} key={index}>{comment.comment}</Typography>
                                            );
                                        })}
                                    </Collapse>
                                </Grid>)}
                            {(items.length - 1 > index) && <Line />}
                        </Grid>
                    );
                })}
            </List>
        </>
    );
}