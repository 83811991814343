import { Box } from "@mui/material";
import { DiscountBanner } from "../../banner/discountBanner";
import { Login } from "./login";

export function LoginPage() {
    return (
        <Box>
            <DiscountBanner />
            <Login />
        </Box>
    );
}